<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showAddOffice('add')" style="margin-right: 10px">添加关键词</a-button>
        </div>
      </div>

      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="showAddOffice(record)" >编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="deleteFn(record)" >删除</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="visible" :title="addOffice ? '添加关键词' : '编辑关键词'" @ok="handleSure" okText="保存">
      <a-form-model :model="addParam" :rules="rules" ref="addForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="评论关键词" prop="antistop">
          <a-input v-model="addParam.antistop" placeholder="输入评论关键词" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  getAntistop,
  addAntistop,
  updateAntistop,
  deleteAntistop
} from "@/request/api";
//引入封装的组件
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {

    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "关键词列表",
        },
      ],


      //请求参数
      queryParam: {
        page: 1,

      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "评价关键词",
          key: "antistop",
          dataIndex: "antistop",
        },
        {
          title: "更新时间",
          key: "updated_at",
          dataIndex: "updated_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      visible: false,
      addOffice: false,

      //更新参数
      addParam: {
        id: "",
        antistop: "",
      },

      //新增表单校验规则
      rules: {
        antistop: [{ required: true, message: "请输入关键词", trigger: "blur" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      getAntistop(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showAddOffice (record) {
      if (record == "add") {
        this.addOffice = true;
        this.addParam = {
          antistop: "",
        };
      } else {
        this.addOffice = false;
        this.addParam = {
          antistop: record.antistop,
          id: record.id,
        };
      }

      this.visible = true;

    },


    //提交表单
    handleSure () {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          if (this.addOffice) {
            addAntistop(this.addParam)
              .then((res) => {
                this.$message.success("添加成功");
                this.visible = false;
                this.queryParam.page = 1;
                this.pagination.current = 1;
                this.getTableData();
              })
              .catch((err) => {
                let errorMsg = err.data.errors.title[0];
                this.$message.error(errorMsg);
              });
          } else {
            updateAntistop(this.addParam.id, this.addParam)
              .then((res) => {
                this.$message.success("修改成功");
                this.visible = false;
                // this.queryParam.page = 1;
                // this.pagination.current = 1;
                this.getTableData();
              })
              .catch((err) => {
                if (err.status == 400) {
                  this.$message.error(err.data.msg);
                }
              });
          }
        }
      });
    },

    //删除
    deleteFn (record) {
      this.$confirm({
        title: `是否删除关键词${record.antistop}?`,
        onOk: () => {
          deleteAntistop(record.id)
            .then((res) => {
              this.$message.success("删除成功");
              this.getTableData();
            })
            .catch((err) => {
              // console.log(err)
              // return
              // if (err.status == 204) {
              //   this.$message.success("删除成功");
              //   this.queryParam.page = 1;
              //   this.pagination.current = 1;
              //   this.getTableData();
              // } else {
              //   this.$message.error(err.data.msg);
              // }
            });
        },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>